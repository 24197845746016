<template>
    <v-dialog
    v-model="alert"
    persistent
    max-width="400"
    >
        <v-card>
            <v-card-title class="text-h5">
                Atención
            </v-card-title>
            <v-card-text>
                {{question}}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="green darken-1"
                text
                @click="$emit('close')"
                >
                    No
                </v-btn>
                <v-btn
                color="green darken-1"
                text
                @click="$emit('execute')"
                >
                    Si
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'QuestionAlert',
        props: ['alert','question']
    }
</script>

<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
				<h2 class="headline">Catálogo de cursos</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.sede.$model"
                                :error-messages="sedeErrors"
                                label="Sede"
                                :items="sedes"
                                item-text="nombre_sede"
                                item-value="id_sede"
                                :loading="loadSede"
                                >
                                </v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="2"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.jornada.$model"
                                :error-messages="jornadaErrors"
                                label="Jornada"
                                :items="jornadas"
                                item-text="nombre_jornada"
                                item-value="id_jornada"
                                :loading="loadJornada"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.level.$model"
                                :error-messages="nivelErrors"
                                label="Nivel"
                                :items="levels"
                                item-text="nombre_nivel"
                                item-value="id_nivel"
                                :loading="loadLevel"
                                @change="getGrades"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="4"
                            sm="12"
                            >
                                <v-select
                                v-model="$v.grade.$model"
                                :error-messages="gradeErrors"
                                label="Grado/Carrera"
                                :items="grades"
                                item-text="nombre"
                                item-value="id"
                                :loading="loadGrade"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <section class="d-flex justify-center">
                            <div>
                                <v-btn
                                @click="submit"
                                :loading="coursesLoad"
                                >
                                    Consultar
                                </v-btn>
                            </div>
                        </section>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card
                v-show="showCourses"
                >
                    <v-card-text>
                        <v-row
                        justify="space-between"
                        >
                            <v-col
                            cols="6"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar"
                                hide-details
                                single-line
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="courses"
                        :search="search"
                        loading=true
                        :loading-text="loadingText"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-menu
                                bottom
                                left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                        @click="edit(item)"
                                        v-can="'courses-edit'"
                                        >
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                        @click="showAlert(item)"
                                        v-can="'courses-delete'"
                                        >
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <template>
            <v-row justify="space-around">
                <v-col cols="auto">
                    <v-dialog
                    v-model="dialog"
                    transition="dialog-bottom-transition"
                    max-width="750"
                    >
                        <v-card>
                            <v-toolbar
                            color="primary"
                            dark
                            >
                                {{formTitle}}
                            </v-toolbar>
                            <v-card-text>
                                <v-form
                                class="mt-2"
                                >
                                    <v-row>
                                        <v-col
                                        cols="6"
                                        >
                                            <v-text-field
                                            label="Sede"
                                            :value="courseItem.nombre_sede"
                                            :error-messages="sedeInputErrors"
                                            :disabled="disb"
                                            ></v-text-field>
                                            <!-- v-model="$v.courseItem.id_sede.$model" -->
                                        </v-col>
                                        <v-col
                                        cols="6"
                                        >
                                            <v-text-field
                                            label="Jornada"
                                            :value="courseItem.nombre_jornada"
                                            :error-messages="jornadaInputErrors"
                                            :disabled="disb"
                                            ></v-text-field>
                                            <!-- v-model="$v.courseItem.id_jornada.$model" -->
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                        cols="6"
                                        >
                                            <v-text-field
                                            label="Nivel"
                                            :value="courseItem.nombre_nivel"
                                            :error-messages="nivelInputErrors"
                                            :disabled="disb"
                                            ></v-text-field>
                                            <!-- v-model="$v.courseItem.id_nivel.$model" -->
                                        </v-col>
                                        <v-col
                                        cols="6"
                                        >
                                            <v-text-field
                                            label="Grado"
                                            :value="courseItem.nombre_grado"
                                            :error-messages="gradoInputErrors"
                                            :disabled="disb"
                                            ></v-text-field>
                                            <!-- v-model="$v.courseItem.id_grado.$model" -->
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                        cols="3"
                                        >
                                            <v-text-field
                                            label="Cod. Curso"
                                            v-model="$v.courseItem.id_curso.$model"
                                            :error-messages="cursoInputErrors"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="9"
                                        >
                                            <v-text-field
                                            label="Nombre curso"
                                            v-model="$v.courseItem.nombre_curso.$model"
                                            :error-messages="nombreCursoErrors"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                        cols="8"
                                        >
                                            <v-text-field
                                            label="Alias curso"
                                            v-model="$v.courseItem.alias_curso.$model"
                                            :error-messages="aliasCursoErrors"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="2"
                                        >
                                            <v-text-field
                                            label="Orden"
                                            v-model="$v.courseItem.orden_curso.$model"
                                            :error-messages="ordenInputErrors"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                        cols="2"
                                        >
                                            <v-select
                                            label="Oficial"
                                            :items="oficial"
                                            item-text="name"
                                            item-value="id"
                                            v-model="$v.courseItem.oficial.$model"
                                            :error-messages="oficialErrors"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="dialog = false"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn
                                color="blue darken-1"
                                text
                                @click="saveOrUpdate"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </template>

        <v-fab-transition>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="pink"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    fixed
                    bottom
                    right
                    fab
                    @click="addnew"
                    v-show="showAddNew"
                    v-can="'courses-create'"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>Agregar curso</span>
            </v-tooltip>
        </v-fab-transition>

        <QuestionAlert
        :alert="onAlert"
        question="¿Esta seguro de eliminar el curso?"
        @close="onAlert = false"
        @execute="deleteItem"
        />
    </section>
</template>
<script>
    import {mapMutations} from "vuex"
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'
    import QuestionAlert from '@/components/QuestionAlert.vue'

    export default {
        mixins: [validationMixin],
        data(){
            return {
				dialog: false,
                onAlert: false,
                disb: true,
                sedes: [],
                sede: '',
                jornada: '',
                level: '',
                grade: '',
                jornadas: [],
                levels: [],
                grades: [],
                loadSede: false,
                loadJornada: false,
                loadLevel: false,
                loadGrade: false,
                coursesLoad: false,
                showCourses: false,

                search: '',
                headers: [
                    {
                        text: 'Orden',
                        value: 'orden_curso'
                    },
                    {
                        text: 'Código grado',
                        value: 'id_grado'
                    },
                    {
                        text: 'Código curso',
                        value: 'id_curso'
                    },
                    {
                        text: 'Nombre curso',
                        value: 'nombre_curso'
                    },
                    {
                        text: 'Alias curso',
                        value: 'alias_curso'
                    },
                    {
                        text: 'Oficial',
                        value: 'oficial'
                    },
                    {
                        text: 'Opciones',
                        value: 'actions',
                        sortable: false
                    },
                ],
                courses: [],
                loadingText: 'Seleccionar grado',
                courseItem: {
                    id: null,
					id_sede: '',
					id_jornada: '',
					id_nivel: '',
					id_grado: '',
                    nombre_sede: '',
                    nombre_jornada: '',
                    nombre_nivel: '',
                    nombre_grado: '',
					orden_curso: '',
					id_curso: '',
					nombre_curso: '',
					alias_curso: '',
                    oficial: ''
                },
                oficial: [
                            {
                                id: 'Si',
                                name: 'Si'
                            },
                            {
                                id: 'No',
                                name: 'No'
                            }
                ],
                showAddNew: false,
                itemToDelete: null
            }
        },
        validations: {
            sede: {required},
            jornada: {required},
            level: {required},
            grade: {required},
			courseItem: {
				id_sede: {required},
                id_jornada: {required},
                id_nivel: {required},
                id_grado: {required},
                orden_curso: {required},
                nombre_curso: {required},
                alias_curso: {required},
                id_curso: {required},
                oficial: {required}
			}
        },
        computed: {
            sedeErrors () {
                const errors = []
                if (!this.$v.sede.$dirty) return errors
                !this.$v.sede.required && errors.push('Elige la sede')
                return errors
            },
            jornadaErrors () {
                const errors = []
                if (!this.$v.jornada.$dirty) return errors
                !this.$v.jornada.required && errors.push('Elige la jornada')
                return errors
            },
            nivelErrors () {
                const errors = []
                if (!this.$v.level.$dirty) return errors
                !this.$v.level.required && errors.push('Elige el nivel')
                return errors
            },
            gradeErrors () {
                const errors = []
                if (!this.$v.grade.$dirty) return errors
                !this.$v.grade.required && errors.push('Elige el/la grado/carrera')
                return errors
            },
            sectionErrors () {
                const errors = []
                if (!this.$v.section.$dirty && this.sections.length <= 0) return errors
                !this.$v.section.required && this.sections.length > 0 && errors.push('Elige el grado de la carrera')
                return errors
            },
            studentErrors () {
                const errors = []
                if (!this.$v.student.$dirty && this.allStudents) return errors
                !this.$v.student.required && !this.allStudents && errors.push('Elige un estudiante')
                return errors
            },
            sedeInputErrors(){
				const errors = []
				if (!this.$v.courseItem.id_sede.$dirty) return errors
                !this.$v.courseItem.id_sede.required && errors.push('Campo requerido')
                return errors
            },
			jornadaInputErrors(){
				const errors = []
				if (!this.$v.courseItem.id_jornada.$dirty) return errors
                !this.$v.courseItem.id_jornada.required && errors.push('Campo requerido')
                return errors
            },
			nivelInputErrors(){
				const errors = []
				if (!this.$v.courseItem.id_nivel.$dirty) return errors
                !this.$v.courseItem.id_nivel.required && errors.push('Campo requerido')
                return errors
            },
			gradoInputErrors(){
				const errors = []
				if (!this.$v.courseItem.id_grado.$dirty) return errors
                !this.$v.courseItem.id_grado.required && errors.push('Campo requerido')
                return errors
            },
            ordenInputErrors(){
				const errors = []
				if (!this.$v.courseItem.orden_curso.$dirty) return errors
                !this.$v.courseItem.orden_curso.required && errors.push('Campo requerido')
                return errors
            },
            cursoInputErrors(){
				const errors = []
				if (!this.$v.courseItem.id_curso.$dirty) return errors
                !this.$v.courseItem.id_curso.required && errors.push('Campo requerido')
                return errors
            },
            nombreCursoErrors(){
				const errors = []
				if (!this.$v.courseItem.nombre_curso.$dirty) return errors
                !this.$v.courseItem.nombre_curso.required && errors.push('Campo requerido')
                return errors
            },
            aliasCursoErrors(){
				const errors = []
				if (!this.$v.courseItem.alias_curso.$dirty) return errors
                !this.$v.courseItem.alias_curso.required && errors.push('Campo requerido')
                return errors
            },
            oficialErrors(){
				const errors = []
				if (!this.$v.courseItem.oficial.$dirty) return errors
                !this.$v.courseItem.oficial.required && errors.push('Campo requerido')
                return errors
            },
            formTitle() {
                return (this.courseItem.id == null) ? "Agregar nuevo curso" : "Editar curso"
            }
        },
        methods: {
            ...mapMutations(['setLayout','setLoading','setNotify']),
            getDropdownsData(){
                this.loadSede = true
                this.loadJornada = true
                this.loadLevel = true

                this.axios.get('/getdropdownsdata')
                .then( (response) => {
                    this.loadSede = false
                    this.loadJornada = false
                    this.loadLevel = false

                    this.sedes = response.data.sedes
                    this.jornadas = response.data.jornadas
                    this.levels = response.data.niveles
                })
                .catch( (error) => {
                    this.loadSede = false
                    this.loadJornada = false
                    this.loadLevel = false
                    console.log(error)
                })
            },
            getGrades(){
                this.loadGrade = true

                this.axios.get('/getgrades/'+this.level)
                .then( (response) => {
                    this.loadGrade = false

                    this.grades = response.data.grados
                })
                .catch( (error) => {
                    this.loadGrade = false
                    console.log(error)
                })
            },
            submit(){
                this.$v.$touch()

                if(!this.$v.sede.$invalid
                    && !this.$v.jornada.$invalid
                    && !this.$v.level.$invalid
                    && !this.$v.grade.$invalid){
                       this.getCoursesCatalogue()
                }
            },
            getCoursesCatalogue(){
                this.showCourses = false
                this.courses = []
                this.coursesLoad = true
                this.loadingText = 'Cargando cursos ...'
                this.setLoading(true)

                this.axios.post('/catalogue/courses',{
                    sede:  this.sede,
                    jornada: this.jornada,
                    level: this.level,
                    grade: this.grade
                })
                .then( (response) => {
                    this.$v.$reset()
                    this.coursesLoad = false
                    this.courses = response.data.courses
                    this.showAddNew = true
                    this.showCourses = true

                    if(this.courses.length <= 0){
                        this.showCourses = false
                        this.setNotify({'show':true,
                                        'color':'primary',
                                        'message':'No se encontró información',
                                        'time':5000})

                        this.loadingText = 'No se encontró información para el criterio de busqueda.'
                    }

                    this.setLoading(false)
                })
                .catch( (error) => {
                    this.coursesLoad = false
                    this.setLoading(false)

                    console.log(error)
                })
            },
            addnew(){
                this.courseItem.id_sede = this.sede
                this.courseItem.id_jornada = this.jornada
                this.courseItem.id_nivel = this.level
                this.courseItem.id_grado = this.grade
                this.courseItem.nombre_sede = this.getOptName(this.sedes,this.sede,'id_sede').nombre_sede
                this.courseItem.nombre_jornada = this.getOptName(this.jornadas,this.jornada,'id_jornada').nombre_jornada
                this.courseItem.nombre_nivel = this.getOptName(this.levels,this.level,'id_nivel').nombre_nivel
                this.courseItem.nombre_grado = this.getOptName(this.grades,this.grade,'id').nombre
                this.dialog = true
            },
            edit(item){
                this.courseItem.id = item.id
                this.courseItem.id_sede = item.id_sede
                this.courseItem.id_jornada = item.id_jornada
                this.courseItem.id_nivel = item.id_nivel
                this.courseItem.id_grado = item.id_grado
                this.courseItem.nombre_sede = this.getOptName(this.sedes,this.sede,'id_sede').nombre_sede
                this.courseItem.nombre_jornada = this.getOptName(this.jornadas,this.jornada,'id_jornada').nombre_jornada
                this.courseItem.nombre_nivel = this.getOptName(this.levels,this.level,'id_nivel').nombre_nivel
                this.courseItem.nombre_grado = this.getOptName(this.grades,this.grade,'id').nombre
                this.courseItem.orden_curso = item.orden_curso
                this.courseItem.id_curso = item.id_curso
                this.courseItem.nombre_curso = item.nombre_curso
                this.courseItem.alias_curso = item.alias_curso
                this.courseItem.oficial = item.oficial
				this.dialog = true
            },
            saveOrUpdate(){
                this.$v.$touch()

                if(!this.$v.courseItem.id_sede.$invalid
                    && !this.$v.courseItem.id_jornada.$invalid
                    && !this.$v.courseItem.id_nivel.$invalid
                    && !this.$v.courseItem.id_grado.$invalid
                    && !this.$v.courseItem.orden_curso.$invalid
                    && !this.$v.courseItem.nombre_curso.$invalid
                    && !this.$v.courseItem.alias_curso.$invalid
                    && !this.$v.courseItem.id_curso.$invalid
                ){
                       if(this.courseItem.id == null){
                           this.create()
                       }else{
                           this.update()
                       }
                }
            },
            update(){
                this.setLoading(true)

                this.axios
                .patch('/catalogue/courses/'+this.courseItem.id,this.courseItem)
                .then( (response) => {
                    this.dialog = false
                    this.setLoading(false)
                    this.setNotify({
                                    'show':true,
                                    'color':'green',
                                    'message':response.data.message,
                                    'time':5300
                                });

                    this.getCoursesCatalogue()
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            },
            create(){
                this.setLoading(true)

                this.axios
                .post('/catalogue/courses/store',this.courseItem)
                .then( (response) => {
                    this.setNotify({
                                    'show':true,
                                    'color':'green',
                                    'message':response.data.message,
                                    'time':5300
                                });

                    this.dialog = false
                    this.setLoading(false)

                    this.courses.push(response.data.course)
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            },
            deleteItem() {
                this.onAlert = false
                this.setLoading(true)

                this.axios.delete('/catalogue/courses/'+this.itemToDelete.id)
                .then( (response) => {
                    this.itemToDelete = null
                    this.setLoading(false)
                    this.setNotify({'show':true,
                                    'color':'success',
                                    'message':response.data.message,
                                    'time':5000})
                    this.getCoursesCatalogue()
                })
                .catch( (error) => {
                    this.setLoading(false)
                    console.log(error)
                })
            },
            showAlert(item) {
                this.itemToDelete = item
                this.onAlert = true
            },
            getOptName(data,id,type) {
                let repository = data.filter((item) => {
                        if(type == 'id_sede') {
                            return item.id_sede == id
                        }

                        if(type == 'id_jornada') {
                            return item.id_jornada == id
                        }

                        if(type == 'id_nivel') {
                            return item.id_nivel == id
                        }

                        if(type == 'id') {
                            return item.id == id
                        }
                });

                return repository[0]
            }
        },
        created(){
            this.getDropdownsData()
            this.setLayout('main-layout')
        },
        watch: {
            "level": function() {
                this.grade = ''
            },
            "dialog": function() {
                if(!this.dialog){
                    this.courseItem.id = null
                    this.courseItem.id_sede = ''
                    this.courseItem.id_jornada = ''
                    this.courseItem.id_nivel = ''
                    this.courseItem.id_grado = ''
                    this.courseItem.orden_curso = ''
                    this.courseItem.id_curso = ''
                    this.courseItem.nombre_curso = ''
                    this.courseItem.alias_curso = ''
                    this.courseItem.oficial = ''
                    this.courseItem.nombre_sede = ''
                    this.courseItem.nombre_jornada = ''
                    this.courseItem.nombre_nivel = ''
                    this.courseItem.nombre_grado = ''
                    this.$v.$reset()
                }
            }
        },
        components: {
            QuestionAlert,
        }
    }
</script>
